<template>
	<main class="main">
		<section class="container container--small">
			<Crumbs :data="crumbs"></Crumbs>

			<div class="main__box">
				<h1 class="main__title">
					Часто задаваемые вопросы
				</h1>

				<ul class="question__list">
					<Question
						:data="item"
						v-for="(item, index) in getFaq"
						:key="index"
					></Question>
				</ul>
			</div>
		</section>

		<div class="bg bg--gray last">
			<section class="main__box main__box--big">
				<div class="container">
					<h2 class="main__title main__title--center">Остались вопросы?</h2>
					<div class="flex flex--center">
						<button class="button" @click="showModalFeedback">
							Задать вопрос
						</button>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import Question from '@/components/Question.vue'

export default {
	name: 'Questions',

	components: {
		Crumbs,
		Question,
	},

	data() {
		return {
			crumbs: [
				{
					link: 'Support',
					text: 'Поддержка',
				},
				{
					link: null,
					text: 'Часто задаваемые вопросы ',
				},
			],
		}
	},

	computed: {
		getFaq() {
			const faq = this.$store.getters.FAQ

			return faq
		},
	},

	methods: {
		showModalFeedback() {
			this.$store.dispatch('GET_MODAL', { type: 2 })
		},
	},

	mounted() {
		this.$store.dispatch('GET_FAQ')
	},
}
</script>
