<template>
	<li class="question__sub-item" v-if="data">
		<button
			class="question__sub-button"
			@click="showContent ? (showContent = false) : (showContent = true)"
		>
			{{ data.title }}
		</button>
		<div
			class="question__content editor"
			v-html="data.content"
			v-if="showContent"
		></div>
	</li>
</template>

<script>
export default {
	name: 'QuestionContent',

	props: {
		data: Object,
	},

	data() {
		return {
			showContent: null,
		}
	},
}
</script>
