<template>
	<li class="question__item" v-if="data" v-click-outside="hide">
		<button
			class="question__button"
			:class="{ active: showList }"
			@click="toggle"
		>
			{{ data.title }}
		</button>
		<div class="question__box" v-if="showList">
			<ul class="question__sub-list">
				<QuestionContent
					:data="item"
					v-for="(item, index) in data.questions"
					:key="index"
				></QuestionContent>
			</ul>
		</div>
	</li>
</template>

<script>
import QuestionContent from '@/components/QuestionContent.vue'

export default {
	name: 'Question',

	components: {
		QuestionContent,
	},

	props: {
		data: {
			type: Object,
		},
	},

	data() {
		return {
			showList: null,
		}
	},

	methods: {
		toggle() {
			this.showList ? (this.showList = false) : (this.showList = true)
		},

		hide() {
			this.showList = false
		},
	},
}
</script>
